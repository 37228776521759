import React from 'react';
import { Notification } from '../../Notification';
import { getInitialPrice } from '../../ItemsList/getInitialPrice';
import { getCurrencyFormat } from '../../../helpers/getCurrencyFormat';
import { useAppTexts } from '../../../hooks/useAppTexts';
import { useLoadedFirebaseData } from '../../../firebase/hooks';
import { FullWizardItem } from '../../../contexts/WizardContext/WizardContext';
import { useOrderData } from '../../../hooks/useOrderData';
import WizardSelector, { WizardSelectorOption } from './WizardSelector';
import {
  Filter,
  shouldRespectPriceIncreaseOnExchange,
} from '../../../../../../functions/src/shared';

interface Props {
  itemData: FullWizardItem;
  onSelect: (data: Filter) => void;
}

const PickAction = ({ itemData, onSelect }: Props) => {
  const appTexts = useAppTexts();
  const {
    config: { respectPriceIncreaseOnExchangeStrategy, returnableOptions },
    publicConfig: { language },
  } = useLoadedFirebaseData();

  const {
    order: { currency },
  } = useOrderData();

  const { product } = itemData;

  const differences = product.variants?.map((variant) => {
    const shouldRespectPriceIncrease = shouldRespectPriceIncreaseOnExchange(
      respectPriceIncreaseOnExchangeStrategy,
      {
        productId: itemData.product.id,
        variantId: itemData.variant.id,
        productTags: itemData.product.tags,
      },
      {
        productId: product.id,
        variantId: variant.id,
        productTags: product.tags,
      },
    );
    const initialPrice = getInitialPrice(itemData, shouldRespectPriceIncrease);
    const parsedVariantPrice = Math.floor(Number(variant.price) * 100);
    const parsedInitPrice = Math.floor(initialPrice * 100);
    const isPriceTooHigh = parsedVariantPrice > parsedInitPrice;
    const priceDifference = isPriceTooHigh ? parsedVariantPrice - parsedInitPrice : 0;

    return priceDifference;
  });

  let refVal = 0;
  let hasMultiple = false;

  const uniqueDiffs = differences.filter((val, index, self) => {
    if (!hasMultiple && val > 0) {
      if (refVal > 0 && refVal !== val) {
        hasMultiple = true;
      } else {
        refVal = val;
      }
    }

    return self.indexOf(val) === index;
  });

  const variantsPriceDifference = {
    value: Math.min(...uniqueDiffs),
    show: uniqueDiffs.indexOf(0) < 0,
    hasMultiple: hasMultiple,
  };

  const options = (returnableOptions || [])
    .filter(({ filterType, filterValue }) => {
      // filter out unavailable options
      if (
        filterType === 'variant' &&
        Array.isArray(product.variants) &&
        product.variants.length <= 1
      )
        return false;
      return filterType !== 'tag' || !filterValue || product.tags.includes(filterValue);
    })
    .flatMap((filter): WizardSelectorOption<Filter>[] => {
      const match = /[^.]*$/.exec(filter.label);

      if (!match) return [];

      const optionKey = match[0];
      const optionLabel =
        filter.label.indexOf('rma.option') > -1 ? appTexts.rma.option[optionKey] : filter.label;

      return [
        {
          label: optionLabel,
          disabled: filter.filterType === 'variant' && product.is_deleted,
          tagRight: filter.filterType === 'variant' && variantsPriceDifference?.show && (
            <span className="tag is-primary item-wizard-option-add-to-price">
              {variantsPriceDifference.hasMultiple && appTexts.itemWizard.from + ' '}+{' '}
              {getCurrencyFormat(variantsPriceDifference.value / 100, currency, language)}
            </span>
          ),
          value: filter,
        },
      ];
    });

  const stepInfoMarkup = appTexts.itemWizard && appTexts.itemWizard.reasonsInfoBottom && (
    <Notification size="small">
      <div dangerouslySetInnerHTML={{ __html: appTexts.itemWizard.reasonsInfoBottom }}></div>
    </Notification>
  );

  return (
    <div className="item-wizard-content-body">
      <WizardSelector options={options} onSelect={onSelect} />
      {stepInfoMarkup}
    </div>
  );
};

export default PickAction;
